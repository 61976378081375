
import { defineComponent, reactive, toRefs, computed } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "PluginNMISMPUser",
  components: {},
  setup() {
    let data = reactive<any>({
      NMUsers: [],
      formInline: {
        phone: "",
      },
    });

    const onSearch = () => {
      if (data.formInline.phone.length != 11) {
        ElMessage.warning({
          showClose: true,
          duration: 10000,
          message: "请输入手机号码",
        });
        return;
      }
      console.info(data.formInline);
      Api.Api2.NMISMPUser(data.formInline).then((res: any) => {
        data.NMUsers = res.data;
      });
    };

    const NMProduct = computed(() => {
      return function (text: string) {
        if (text == "107000000000000005026") {
          return "预约挂号：不限次<br>在线问诊：5次/月<br>营养配餐：5次/月<br>智能问诊：不限次<br>中医体检测试：不限次<br>预约陪诊服务：1次/年（免费）；2次/年（129元）；无限次/年（300元）";
        }
        if (text == "107000000000000005022") {
          return "预约挂号：不限次<br>在线问诊：不限次<br>营养配餐：不限次<br>智能问诊：不限次<br>中医体检测试：不限次<br>预约陪诊服务：5次/年（免费）；不限次（129元）<br>体检、口腔（优惠预约）：不限次";
        }
        if (text == "107000000000000005024") {
          return "预约挂号：不限次<br>在线问诊：10次/月<br>营养配餐：10次/月<br>智能问诊：不限次<br>中医体检测试：不限次<br>预约陪诊服务：2次/年（免费）；2次/年（129元）；无限次/年（300元）<br>体检、口腔（优惠预约）：不限次";
        }
        return "未知";
      };
    });

    const NMUserStatus = computed(() => {
      return function (text: number) {
        if (text == 1) {
          return "开通";
        } else {
          return "取消";
        }
      };
    });

    return {
      ...toRefs(data),
      onSearch,
      NMProduct,
      NMUserStatus,
    };
  },
});
