
import { defineComponent, reactive, toRefs, onMounted, ref } from "vue";
import store from "@/store";
import TodayRegList from "@/components/plugin/TodayRegList.vue";
import NMISMPUser from "@/components/plugin/NMISMPUser.vue";
import ConfigXjCrmUser from "@/components/config/XinJiangUser.vue";
import AHCRMUser from "@/components/plugin/AHCRMUser.vue";
// import { fa } from "element-plus/es/locale";

export default defineComponent({
  name: "Work",
  components: { TodayRegList, NMISMPUser, ConfigXjCrmUser, AHCRMUser },
  setup() {
    let data = reactive<any>({
      RoleInfo: "00000000",
      TodayRegListShow: false,
      NMISMPUserShow: false,
      XJDXUserShow: false,
      AHCRMUserShow: false,
    });

    const TodayRegListRef = ref<InstanceType<typeof TodayRegList>>();
    //const NMISMPUserRef = ref<InstanceType<typeof NMISMPUser>>();

    onMounted(() => {
      const roleInfo: string = store.getters.roleInfo;
      console.log(roleInfo);
      if (roleInfo) {
        data.RoleInfo = roleInfo;
        if (data.RoleInfo.substring(0, 1) == 1) {
          data.TodayRegListShow = true;
          TodayRegListRef.value?.getOption();
        }
        if (data.RoleInfo.substring(2, 3) == 1) {
          data.NMISMPUserShow = true;
        }
        if (data.RoleInfo.substring(3, 4) == 1) {
          data.XJDXUserShow = true;
        }
        console.log(data.RoleInfo.substring(7, 8));

        if (data.RoleInfo.substring(7, 8) == 1) {
          data.AHCRMUserShow = true;
        }
      }
    });

    return {
      ...toRefs(data),
      TodayRegListRef,
    };
  },
});
