
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import excel from "avue-plugin-excel";
import store from "@/store";

export default defineComponent({
  name: "PluginTodayRegList",
  components: {},
  setup() {
    let data = reactive<any>({
      init: false,
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "hospitalname",
      },
      role: "",
    });

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Config.GetTodayRegList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Config.GetTodayRegListOption().then((res: any) => {
        data.option = res;
        data.init = true;
      });
    };

    const downExcel = (row: any) => {
      const where: any = {};
      where.jzdates = row.jzdate;
      where.jzdatee = row.jzdate;
      where.hospitalid = row.hospitalid;
      const query = {
        where: where,
        limit: 10,
        page: 1,
        sort: "-id",
      };
      Api.Register.DownloadRegList(query).then((res: any) => {
        console.log(res);
        if (res.Code == 0) {
          excel.excel({
            title: row.hospitalname + "（" + row.jzdate + "）",
            columns: res.Data.column,
            data: res.Data.data,
          });
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    onMounted(() => {
      data.role = store.getters.role;
    });

    return {
      ...toRefs(data),
      changePage,
      getOption,
      downExcel,
    };
  },
});
