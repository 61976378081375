
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import ChaKanXiangQing from "./XinJiangUserLog.vue";
import ChaKanTouSu from "./TouSu.vue";
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigXjCrmUser",
  components: { ChaKanXiangQing,ChaKanTouSu },
  setup() {
    let data = reactive<any>({
      init: false,
      maps: new Map(),
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-OrderId",
      },
      xiangqingDialogVisible: false,
      tousuDialogVisible: false,
    });

    const searchChange = (params: any, done: any) => {
      for (let p in params) {
        const field: Column = data.option.column.find(
          (v: Column) => v.prop === p
        );
        if (field.regex) {
          params[p] = { $regex: params[p] };
        }
      }
      // console.log(params);
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      if (!data.query.where["phone"]) {
        return;
      }
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      Api.Config.GetXjCrmUserList(data.query).then((res: any) => {
        // console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
          data.data.data.forEach((element: any) => {
            element.packagesFee = element.packagesFee / 100 + "元";
            if (element.status == false) {
              element.status = "退订";
            } else {
              element.status = "正常";
            }
          });
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Config.GetXjCrmUserListOption().then((res: any) => {
        data.option = res;
        data.init = true;
      });
    };

    const rowSave = (form: any, done: any, loading: any) => {
      console.log(form);
      data.query.where["phone"] = form.phone;
      if (form.isTrial == 1) {
        Api.Config.AddXjCrmUser(form).then((res: any) => {
          if (res.Code == 0) {
            ElMessage.success("开通成功");
            getList();
            done();
          } else {
            loading();
            ElMessage.warning({
              showClose: true,
              duration: 10000,
              message: res.ResultMsg,
            });
          }
        });
      } else {
        Api.Config.AddXjCrmUsertiyan(form).then((res: any) => {
          if (res.Code == 0) {
            ElMessage.success("开通成功");
            getList();
            done();
          } else {
            loading();
            ElMessage.warning({
              showClose: true,
              duration: 10000,
              message: res.ResultMsg,
            });
          }
        });
      }
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      Api.Config.EditHospital(form.HospitalID, form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const XjCrmUserRemove = (row: any, done: any, loading: any) => {
      ElMessageBox.prompt(
        `请确认（${row.phone}用户）取消该服务的计费并输入备注`,
        `取消计费确认`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(({ value }) => {
          console.log(value);
          const action = {
            phone: row.phone,
            packagesId: row.packagesId,
            memo: value ?? "",
          };

          data.query.where["phone"] = row.phone;
          Api.Config.cancelXjCrmUser(action).then((res: any) => {
            if (res.Code == 0) {
              ElMessage.success("取消成功");
              getList();
              // done();
            } else {
              // loading();
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });
        })
        .catch(() => {
          //console.log("xx");
        });
    };

    const rowDel = (row: any, done: any, loading: any) => {
      // console.log('row.status');
      // console.log(row.status);
      console.log(row);
      ElMessageBox.confirm(
        `请确认（${row.phone}用户）取消该服务的体验`,
        `取消体验确认`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const action = {
            phone: row.phone,
            packagesId: row.packagesId,
            memo: "",
          };

          data.query.where["phone"] = row.phone;
          Api.Config.cancelXjCrmUsertiyan(action).then((res: any) => {
            if (res.Code == 0) {
              ElMessage.success("取消成功");
              getList();
              // done();
            } else {
              loading();
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });
        })
        .catch(() => {
          //console.log("xx");
        });
    };
    const ChaKanXiangQingRef = ref<InstanceType<typeof ChaKanXiangQing>>();
    const SetXiangQing = (row: any) => {
      data.xiangqingDialogVisible = true;
      ChaKanXiangQingRef.value?.searchChange(row.orderId);
    };


    const ChaKanTouSuRef = ref<InstanceType<typeof ChaKanTouSu>>();
    const SetTouSu = (row: any) => {
      data.tousuDialogVisible = true;
      ChaKanTouSuRef.value?.searchChange(row);
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      rowSave,
      rowUpdate,
      rowDel,
      XjCrmUserRemove,
      SetXiangQing,
      ChaKanXiangQingRef,
      SetTouSu,
      ChaKanTouSuRef,
    };
  },
});
