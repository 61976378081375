import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfigXjCrmUser = _resolveComponent("ConfigXjCrmUser")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_TodayRegList = _resolveComponent("TodayRegList")!
  const _component_NMISMPUser = _resolveComponent("NMISMPUser")!
  const _component_AHCRMUser = _resolveComponent("AHCRMUser")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_ConfigXjCrmUser, { ref: "ConfigXjCrmUserRef" }, null, 512)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.XJDXUserShow]
    ]),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_TodayRegList, { ref: "TodayRegListRef" }, null, 512)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.TodayRegListShow]
        ]),
        _createVNode(_component_el_col, { span: 12 })
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_NMISMPUser, { ref: "NMISMPUserRef" }, null, 512)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.NMISMPUserShow]
    ]),
    _withDirectives(_createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_AHCRMUser, { ref: "AHCRMUserRef" }, null, 512)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.AHCRMUserShow]
    ])
  ], 64))
}